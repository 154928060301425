<template>
<div class="home">
<!--  <div class="top">-->
<!--    <div class="userName">-->
<!--      <span>欢迎您:{{$store.getters.username}}</span>-->
<!--    </div>-->
<!--    <div class="exit" @click="loginout">-->
<!--      <i class="el-icon-switch-button" style="font-size:15px;font-weight: 800;color:#3C64BC"></i>-->
<!--      <span>退出登录</span>-->
<!--    </div>-->
<!--  </div>-->
  <div class="header">
    <div class="top">
      <div class="userName">
        <span>欢迎您:{{username}}</span>
      </div>
      <div class="exit" @click="logout">
        <i class="el-icon-switch-button" style="font-size:15px;font-weight: 800;color:#3C64BC"></i>
        <span>退出登录</span>
      </div>
    </div>
    <div class="logo_box">
<!--      <div class="logo"><img src="@/assets/logo.png" alt=""></div>-->
<!--      <div class="logo_word">创新卓越人才培养体系，助力信息产业超越式发展</div> -->
    </div>
  </div>
  <div class="tab">
    <div  class="tab_list">

      <el-menu
                active-text-color="#fff"
                text-color="#fff"
                mode="horizontal"
                @select="handleSelect"
                background-color="#3A63B3"
      >
<!--        <el-menu-item index="1" @click="aClick('first')">首页</el-menu-item>-->

<!--        <el-sub-menu v-if="isManager||isBusiness||(!isBusiness&&!isManager)"  index="4">-->
<!--          <template   #title ><div style="font-size:18px">科技动态</div></template>-->
<!--          <el-menu-item  v-if="isManager||isBusiness" @click="aClick('myNews')" index="4-2">新闻上传</el-menu-item>-->
<!--          <el-menu-item  @click="aClick('newsList')" index="4-1">新闻展示</el-menu-item>-->
<!--          <el-menu-item v-if="isManager" @click="aClick('myNewsCheck')" index="4-2">新闻审核</el-menu-item>-->
<!--        </el-sub-menu>-->

        <el-sub-menu v-if="isManager"  index="5">
          <template   #title ><div style="font-size:18px">通知公告</div></template>
<!--          <el-menu-item  @click="aClick('announceList')" index="5-1">通知公告</el-menu-item>-->
          <el-menu-item  v-if="isManager||isBusiness" @click="aClick('myAnnouncement')" index="5-2">通知上传</el-menu-item>
        </el-sub-menu>

<!--        <el-sub-menu v-if="isManager||isBusiness"  index="2">-->
<!--          <template   #title ><div style="font-size:18px">成果推广</div></template>-->
<!--          <el-menu-item v-if="isManager" @click="aClick('achievementCheck')" index="2-2">推广审核</el-menu-item>-->
<!--          <el-menu-item v-if="isBusiness" @click="aClick('myAchievement')" index="2-3">推广上传</el-menu-item>-->
<!--          <el-menu-item  @click="aClick('achievementList')" index="2-1">推广展示</el-menu-item>-->
<!--        </el-sub-menu>-->

<!--        <el-sub-menu v-if="isManager||isBusiness"  index="3">-->
<!--          <template   #title ><div style="font-size:18px">科技需求</div></template>-->
<!--          <el-menu-item v-if="isManager" @click="aClick('requirementCheck')" index="3-1">需求审核</el-menu-item>-->
<!--          <el-menu-item v-if="isBusiness" @click="aClick('myRequirement')" index="3-2">需求上传</el-menu-item>-->
<!--          <el-menu-item v-if="isBusiness||isManager" @click="aClick('arRequirement')" index="3-2">需求展示</el-menu-item>-->
<!--        </el-sub-menu>-->

<!--        <el-sub-menu v-if="isManager||isBusiness||(!isBusiness&&!isManager)"  index="7">-->
<!--          <template   #title ><div style="font-size:18px" >评价服务</div></template>-->
<!--          <el-menu-item  @click="aClick('assessList')" index="7-1">评价展示</el-menu-item>-->
<!--          <el-menu-item v-if="isManager" @click="aClick('assessCheck')" index="7-2">评价审核</el-menu-item>-->
<!--          <el-menu-item  @click="aClick('myAssess')" index="7-2">评价上传</el-menu-item>-->
<!--        </el-sub-menu>-->

<!--        <el-sub-menu v-if="isManager"  index="6">-->
<!--          <template   #title ><div style="font-size:18px">单位</div></template>-->
<!--          <el-menu-item  @click="aClick('companyList')" index="6-1">单位展示</el-menu-item>-->
<!--          <el-menu-item  v-if="isManager" @click="aClick('myCompany')" index="6-2">单位上传</el-menu-item>-->
<!--        </el-sub-menu>-->

        <el-sub-menu v-if="isManager"  index="8">
          <template   #title ><div style="font-size:18px">文件上传</div></template>
          <el-menu-item  @click="aClick('fileKindList')" index="8-1">文件上传</el-menu-item>
<!--          <el-menu-item  @click="aClick('wordUploadEdit')" index="8-2">word/pdf/excel上传</el-menu-item>-->
        </el-sub-menu>

        <el-sub-menu v-if="isManager" index="9">
          <template   #title ><div style="font-size:18px">论坛管理</div></template>
          <router-link :to="{ name: 'CarouselManage' }"><el-menu-item index="9-1">轮播图管理</el-menu-item></router-link>
          <router-link :to="{ name: 'NoticeManage' }"><el-menu-item index="9-2">公告管理</el-menu-item></router-link>
          <router-link :to="{ name: 'AreaManage' }"><el-menu-item index="9-3">区块管理</el-menu-item></router-link>
          <router-link :to="{ name: 'CategoryManage' }"><el-menu-item index="9-4">板块管理</el-menu-item></router-link>
          <router-link :to="{ name: 'TagManage' }"><el-menu-item index="9-5">标签管理</el-menu-item></router-link>
          <router-link :to="{ name: 'TopicManage' }"><el-menu-item index="9-6">话题管理</el-menu-item></router-link>
          <router-link :to="{ name: 'BanManage' }"><el-menu-item index="9-6">封禁管理</el-menu-item></router-link>
        </el-sub-menu>

        <el-sub-menu v-if="isManager" index="10">
          <template   #title ><div style="font-size:18px">账户管理</div></template>
          <router-link :to="{ name: 'UserManage' }"><el-menu-item index="10-1">用户管理</el-menu-item></router-link>
          <router-link :to="{ name: 'RoleManage' }"><el-menu-item index="10-2">角色管理</el-menu-item></router-link>
          <router-link :to="{ name: 'AuthorizeManage' }"><el-menu-item index="10-3">权限管理</el-menu-item></router-link>
        </el-sub-menu>
<!--        <el-sub-menu v-if="isManager"  index="11">-->
<!--          <template   #title ><div style="font-size:18px">培训申请</div></template>-->
<!--          <el-menu-item v-if="isManager" @click="aClick('trainingApplication')" index="11-1">培训申请</el-menu-item>-->
<!--        </el-sub-menu>-->
        <el-sub-menu v-if="isManager"  index="12">
          <template   #title ><div style="font-size:18px">报名审核</div></template>
          <el-menu-item  @click="aClick('process')" index="12-1">报名审核</el-menu-item>
        </el-sub-menu>
      </el-menu>


    </div>
  </div>
  <div class="main">
    <div class="content_home">
      <router-view/>
    </div>
  </div>
  <div class="footer_line">

  </div>

  <div class="footer_home">
    <!--      友情链接-->
    <div class="box_link">
      <ul>
        <li  v-for="(item) in urlList" :key="item.id" ><el-button @click="goto(item)">{{item.name}}</el-button></li>

      </ul>
    </div>
    <div style="font-size: 14px; font-weight: bold; color: white;">
<!--      <span>©2022 AEE专家进高校</span><br> -->
      <span>技术支持单位：金陵科技学院 </span>
<!--      <span><a class="a" href="http://www.qi-tech.com.cn/" target="_blank">北京奇观技术有限责任公司</a></span><br>-->
      <span>开发单位：南京雄君软件科技有限公司</span><br>
<!--      <span><a class="a" href="https://beian.miit.gov.cn/" target="_blank">京ICP备05032270号-3</a></span>-->

      <span><a class="a" href="https://beian.miit.gov.cn/" target="_blank">苏ICP备18033501号-5</a></span>
    </div>
  </div>
  <div class="bottom_home">

  </div>
</div>
</template>
<script>
export default {
  data() {
    return {
      username:'',
      //是否是管理员
      isManager:false,
      //是否是商家
      isBusiness:false,
      urlList:[],
    }
  },




  computed:{

  },

  created(){
    this.$request.get("/account/getUserInfo").then(res=>{
      if(res.obj==null){
        this.logout();
      }else{
        this.isManager = res.obj.isManager;
        this.isBusiness = res.obj.isBusiness;
      }
      console.log('userInfo',res.obj);
      console.log('12349')
      this.getlink();
    });
  },
  mounted(){
    // this.filter();
    // if(this.$store.getters.username === '') this.loginout()
    // if (this.$store.getters.username === null) this.$router.push('/');
    // for(let item of this.tabList){
    //   if(this.$route.name === item.value){
    //     document.getElementById(this.$route.name).style.background="#648AD3";
    //     return
    //   }
    // }
  },
  beforeDestroy(){
  },
  watch:{
    // messageTableVisible(newVal,oldVal){
    //   if(newVal === false){
    //     this.messageData = []
    //   }
    // },
    // messageVisible(newVal,oldVal){
    //   if(newVal === false){
    //     this.mission = {};
    //     this.filter()
    //   }
    // },
    // $route(to,from){
    //   for(let item of this.tabList){
    //     if(to.name === item.value){
    //       document.getElementById(to.name).style.background="#648AD3";
    //     }
    //     if(from.name === item.value){
    //       document.getElementById(from.name).style.background="#3A63B3";
    //     }
    //   }
    // },
  },
  methods:{
    //友情链接跳转
    goto(value){
      window.open(value.url)
    },
    //获取友情链接
    //  友情链接

    getlink(){
      this.$request.post("/FileUpload/getUrl",).then(res=>{
        this.urlList=res.obj;
        // console.log('======================',this.urlList)
      })
    },
    aClick(value){
      console.log(value);
      this.$router.push('/'+value);
    },

    logout(){
      this.$request.post("/jwtUser/logout").then(res=>{
        if(res.obj=="注销成功"){
          this.$store.commit("logout");
          this.$router.push('/');
          // this.$msg.success('已退出登录')
        }else{
          this.$msg({
            message: res.obj,
            type: 'warning'
          });
        }
      });
    }
  }
};
</script>
<style lang="less">
.home{
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  .a:link{
    text-decoration: none;
    color: white;
  }
  .a:visited{
    color: white;
  }
  //.top{
  //  width: 100%;
  //  height: 20pt;
  //  background: #F2F2F2;
  //  color: #7D7D7B;
  //  display: flex;
  //  float: left;
  //  .userName{
  //    height: 20px;
  //    line-height: 20px;
  //    font-size: 12px;
  //    margin-left: 10%;
  //    width: 40%;
  //  }
  //  .exit{
  //    float: right;
  //    text-align: right;
  //    margin-right: 10%;
  //    font-size: 12px;
  //    height: 20px;
  //    width: 40%;
  //    line-height: 20px;
  //  }
  //  .exit:hover{
  //    cursor: pointer;
  //  }
  //}
  .header{
    height: 81pt;
    width: 100%;
    //background-image: #F5F5F5;
    background: url(../assets/homeBackgroundIcon.png) no-repeat right center;
    .top{
      width: 100%;
      height: 20pt;
      //background: #F2F2F2;
      //color: #7D7D7B;
      display: flex;
      float: left;
      .userName{
        height: 20px;
        line-height: 90px;
        font-size: 12px;
        margin-left: 10%;
        width: 40%;
      }
      .exit{
        float: right;
        text-align: right;
        margin-right: 10%;
        font-size: 12px;
        height: 20px;
        width: 40%;
        line-height: 90px;
      }
      .exit:hover{
        cursor: pointer;
      }
    }
    .logo_box{
      width: 90%;
      height: 81pt;
      margin:auto;
      .logo{
        float: left;
        width: 100%;
        height: 41pt;
        margin-top: 10pt;
        img{
          margin-top: 20pt;
          width: 300px;
        }
      }
      .logo_word {
        line-height: 40pt;
        color: #94979E;
        font-size: 15px;
      }
    }
  }
  .tab{
    width: 100%;
    height: 50px;
    background: #3A63B3;
    .tab_list{
      //margin: 0 auto;
      width:1200px;
      height:50px;
      .el-menu-item{
        float: left;
        margin-left: 60px;
        padding-left: 20px;
        padding-right: 20px;
        height: 50px;
        line-height: 50px;
        color:#fff;
        font-size: 18px;
        font-weight: bold;
      }
      .el-sub-menu{
        float: left;
        margin-left: 0px;
        padding-left: 20px;
        padding-right: 20px;
        height: 50px;
        line-height: 50px;
        color:#3A63B3;
        font-size: 18px;
        font-weight: bold;
      }
      .el-menu-item:hover{
        cursor: pointer;
      }
    }
  }
  .main{
    width: 100%;
    min-height: 100%;
    margin: auto;
    background: #F6F7FC;
    padding-top: 20px;
    padding-bottom: 20px;
    display: grid;
    .content_home{
      border-radius:20px;
      background: white;
      width: 95%;
      margin-left: 2.5%;
    }
  }
  .footer_line{
    //margin-top: 50px;
    height: 50px;
    background: #F6F7FC;
    border-top: 3px dotted #3A63B3;
    //  友情链接

  }
  .footer_home{
    height: 91px;
    width: 100%;
    background: url(../assets/bottomBackground.png) no-repeat;
    background-size: 100%;
    text-align: center;
    line-height: 20px;
    padding-top: 16px;
  }
  .bottom_home{
    color: black;
    height: 50px;
    text-align: center;
    background: #F5F5F5;
    //padding-top: 20px;
  }
}
.box_link{
  //background-color: #CCCCCC;
  height: 50px;
  ul {
    height: 100%;
    width: 100%;
    text-align: center;
    li {
      display: inline-block;
      padding: 5px 10px;
      list-style: none;
    }
  }
}
</style>
