import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios/axios'


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@/ckeditor/ckeditor.js';
import VueCookies from 'vue-cookies'
import {ElMessage } from 'element-plus'


import './reset.css'
import dataFormat from './util/dataFormat'
import dateUtil from './util/dateUtil'
import Check from './util/check'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import globalData from './util/globalData'

import i18n from './language'
// 挂载 i18n
import VueQrcode from '@chenfengyuan/vue-qrcode';
//
window.ClassicEditor = ClassicEditor
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}

const app = createApp(App)
app.use(i18n)
app.use(store).use(router).use(axios).use(ElementPlus).use(CKEditor).use(VueCookies).use(Check).use(dataFormat).use(dateUtil);
app.component(VueQrcode.name, VueQrcode);
app.config.globalProperties.$msg = ElMessage; //配置全局对信息提示
app.config.globalProperties.$check = Check
app.config.globalProperties.$cookies = VueCookies

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')
