//创建英文语言包对象
export default {
    title1: 'The 2nd IEEE International Summer School on E-CARGO and Applications',
    title2: 'Guangzhou, China(Hybrid), July 22, 2024- July 26, 2024',
    lang: '中文',
    login: {
        login: 'Login',
        login_button: 'Login/Register',
        login_register_frst:'Please log in/register first!',
        exit_login: 'Exit login',
        username: 'Username',
        user_exist:'This user is already exist',
        phone_exist:'The mobile phone number has been bound',
        register_error:'Registration failure',
        register_success:'Successful registration',
        email: 'Email',
        phone_number: 'Telephone',
        password: 'Password',
        password_remember: 'Remember password',
        password_login: 'Password login',
        register: 'Register',
        phone_p: 'Please enter your phone number',
        password_p: 'Please enter your password',
        password_p_2: 'Please enter your password twice',
        email_p: 'Please enter your Email',
        no_null:'Mobile phone number and password cannot be empty',
        login_error:'Incorrect user name or password ',
        login_success:'Login success!',
        login_info:'用户名或密码错误',
        must_login:'Please login first',
        choose_q1:'Problem one is not selected',
        choose_q2:'Problem two is not selected',
        login_out:'login out',
        enroll_now:'Enroll Now!'
    },
    sign_up: {
        SampleRegistration:'Example of identity:',
        SamplePayment:'Sample paid images:',
        sign_up: 'Enroll Now!',
        q_1: '1)Did you join IEEE for this summer school?',
        q_2: '2)Did you join SMCS for this summer school?',
        confirm: 'confirm',
        invoice:'invoice',
        registration_form: 'Registration Form',
        first_name:'First Name',
        last_name:'Last Name',
        gender: 'Gender',
        male:'Male',
        female:'Female',
        not_disclose:'Not disclose',
        nationality:'Nationality',
        birth_date:'Birth Date',
        choose_date:'Choose Date',
        affiliation:'Affiliation',
        position:'Position',
        research_interests: 'Research Interests',
        contact_address:'Contact Address',
        telephone:'Telephone',
        email:'E-mail',
        member_or_not:'Member or not',
        student_or_not:'Student or not',
        yes:'Yes',
        no:'No',
        upload_your_profile:'Upload your profile',
        cancel:'Cancel',
        must_input:'required',
        identity:'Identity selection',
        identity1:'IEEE SMC student member',
        identity2:'IEEE SMC member',
        identity3:'IEEE/INCOSE student member',
        identity4:'IEEE/INCOSE member',
        identity5:'non-IEEE student',
        identity6:'non-IEEE member',
        participate_Method:'Participation method',
        participate_Method_online:'Online',
        participate_Method_offline:'Offline',
        last_step:'Previous step',
        upload_pic:'upload your picture',
        upload_success:'upload success!',
        pay: {
            title1: 'Dear user',
            title2: ', please pay',
            title3: 'C$',
            content1:' Payment Method 1: paypal',
            content2:' Link :',
            content3:' Payment Method 2: Bank Transfer ',
            content4:' Nanjing Xiongjun Software Technology Co., LTD. ',
            content5: "Bank: China Merchants Bank Co., LTD. Nanjing Yueyahu Branch",
            content6:' Bank Address: 88 Shouzouyuan Street, Qinhuai District, Nanjing ',
            content7:' Bank Account Number :1259 1030 9110 401',
            content8:' Please note your name and mobile number when transferring ',
            content9:' Payment Method 3: wechat Payment ',
            content10:'Supplementary payment（Enter Canadian dollars）',
            ensure_upload:'Confirm',
            upload_payment:'Upload payment picture.If you don\'t need to pay, you don\'t need to upload it',
        }
    },
    invoice: {
        registration_form: 'Registration Form',
        invoiceName: 'Unit Name',
        invoiceNumber: 'Duty Paragraph',
        invoiceAddress: 'Unit Address',
        must_input: 'required',
        invoiceMail: 'E-mail',
        confirm: 'Confirm',
        cancel: 'Cancel',
    },
    menus: {
        first:'The First Summer School',
        home: 'Home',
        forum: 'Forum',
        IEEESMC: 'Join IEEE/SMC',
        showQQ: 'Show QQ Group QR Code',
        joinQQ: 'Hello, please join our QQ group.'
    },
    tabs: {
        info: 'notice',
        comment: 'Comment On',
        download: 'Download',
        sign_up: 'Registration application',
        navigation: 'guide',
        more: 'more +',
        announcement:'notice',
        groups: 'groups'
    },
    captcha: {
        content1:'Complete security verification',
        content2:'Swipe right to complete the verification',
        fail:'verification failed',
    }
}