// 1、创建中文语言包对象
export default{
    title1: 'IEEE第二届E-CARGO及其应用国际暑期学校(在线)',
    title2: '中国广州，2024年7月22日-2024年7月26日',
    lang: 'English',
    login: {
        login: '登录',
        register: '注册',
        login_register_frst:'请先登录/注册',
        login_button: '登录/注册',
        exit_login: '退出登录',
        username: '用户名',
        user_exist:'用户已存在',
        phone_exist:'手机号已被绑定',
        register_error:'注册失败',
        register_success:'注册成功',
        email: '邮箱',
        phone_number: '手机号',
        password: '密码',
        password_remember: '记住密码',
        password_login: '密码登录',
        phone_p: '请输入手机号',
        password_p: '请输入密码',
        password_p_2: '请再次输入密码',
        email_p: '请输入邮箱(可不填)',
        login_error:'用户名或密码错误',
        no_null:'手机号和密码不能为空',
        login_success:'登录成功',
        must_login:'请登录',
        choose_q1:'问题一未选择',
        choose_q2:'问题一未选择',
        login_out:'已退出登录',
        enroll_now:'立即报名',
    },
    sign_up: {
        SampleRegistration:'身份信息样例:',
        SamplePayment:'支付信息样例:',
        sign_up: '现在报名！',
        q_1: '1)你是为参加本暑期班加入IEEE的？Yes,No.',
        q_2: '2)你是为参加本暑期班加入SMCS的？Yes,No.',
        confirm: '确定',
        invoice:'发票',
        registration_form: '登记表',
        first_name:'名字',
        last_name:'姓氏',
        gender: '性别',
        male:'男',
        female:'女',
        not_disclose:'未披露',
        nationality:'国籍',
        birth_date:'出生日期',
        choose_date:'选择日期',
        affiliation:'单位',
        position:'单位地址',
        research_interests: '研究兴趣',
        contact_address:'联系地址',
        telephone:'电话',
        email:'邮箱',
        member_or_not:'是否成员',
        student_or_not:'是否学生',
        yes:'是',
        no:'否',
        upload_your_profile:'上传你的个人资料',
        cancel:'取消',
        must_input:'必填',
        identity:'身份选择',
        identity1:'IEEE SMC 学生会员',
        identity2:'IEEE SMC 会员',
        identity3:'IEEE/INCOSE学生会员',
        identity4:'IEEE/INCOSE会员',
        identity5:'非IEEE 学生',
        identity6:'非IEEE 会员',
        participate_Method:'参与方式',
        participate_Method_online:'线上',
        participate_Method_offline:'线下',
        last_step:'上一步',
        upload_pic:'请上传图片',
        upload_success:'上传成功!',
        pay: {
            title1: '尊敬的 ',
            title2: '，请缴纳',
            title3: '加元',
            content1:'缴费方式一: paypal',
            content2:'链接:',
            content3:'缴费方式二: 银行转账',
            content4:'南京雄君软件科技有限公司',
            content5:'开户行:招商银行股份有限公司南京月牙湖支行',
            content6:'开户行地址: 南京巿秦淮区首蓿园大街88号',
            content7:'银行账号:1259 1030 9110 401',
            content8:'转账时请备注姓名和手机号',
            content9:'缴费方式三: 微信支付',
            content10:'补缴（输入加元）',
            ensure_upload:'确认',
            upload_payment:'上传缴费图片，无需缴费则不用上传',
        }
    },
    invoice: {
        registration_form: '登记表',
        invoiceName: '单位名称',
        invoiceNumber: '纳税人识别号',
        invoiceAddress: '单位地址',
        must_input: '必填',
        invoiceMail: '邮箱',
        confirm: '确定',
        cancel: '取消',
    },
    menus: {
        first:'第一届',
        home: '首页',
        forum: '论坛',
        IEEESMC: '加入IEEE/SMC',
        showQQ: '展示QQ群二维码',
        joinQQ: '您好，请加入我们的QQ群。'
    },
    tabs: {
        info: '通知公告',
        comment: '评价',
        download: '资料下载',
        sign_up: '报名登记',
        navigation: '导航',
        more:'更多 +',
        announcement:'通知公告',
        groups: '群聊'
    },
    captcha: {
        content1:'请完成安全验证',
        content2:'向右滑动完成验证',
        fail:'验证失败',
        success:'验证成功'
    }
}